import React, { useEffect, useState } from "react";

//dayjs
import dayjs from "dayjs";

//jquery
import $ from "jquery";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//routing
import { Link, useHistory } from "react-router-dom";

//MUI
import { Tooltip } from "@material-ui/core";

// import arraySort from "array-sort";

import { permissionError } from "../../util/Alert";

//pagination
import Pagination from "../../Page/Pagination";

//Date Range Picker
import { DateRangePicker } from "react-date-range";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import boy from "../../assets/img/boy.png";
import girl from "../../assets/img/girl-3.png";
//action
import {
  getFakeUser,
  handleBlockUnblockSwitch,
  // fakeUserIsLive,
} from "../../Store/FakeUser/Action";
import { baseURL } from "../../util/config";
import "../../assets/css/elements/search.css";
import { PowerInputSharp } from "@material-ui/icons";
import { OPEN_SPINNER_PROGRESS } from "../../Store/spinner/types";

const FakeUserTable = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const hasPermission = useSelector((state) => state.admin.user.flag);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");

  const [date, setDate] = useState([]);
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");

  useEffect(() => {
    $("#card").click(() => {
      $("#datePicker").removeClass("show");
    });
  }, []);
  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", girl);
    });
  });
  const maxDate = new Date();

  useEffect(() => {
    search == "" && dispatch({ type: OPEN_SPINNER_PROGRESS });
    dispatch(getFakeUser(page, rowsPerPage, search, sDate, eDate));
  }, [dispatch, page, rowsPerPage, search, sDate, eDate]);

  const { user, totalUser } = useSelector((state) => state.fakeUser);

  useEffect(() => {
    setData(user);
  }, [user]);

  useEffect(() => {
    if (date.length === 0) {
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    $("#datePicker").removeClass("show");
    setData(user);
  }, [date, user]);

  //for pagination
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setPage(1);
    setRowsPerPage(value);
  };

  // set default image

  const handleBlockUnblockSwitch_ = (userId) => {
    if (!hasPermission) return permissionError();
    props.handleBlockUnblockSwitch(userId);
  };

  const handleUserInfo = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    history.push("/admin/user/userInfo");
  };
  const handleUserHistory = (user) => {
    // localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("HistoryUser", JSON.stringify(user));

    history.push("/admin/user/history");
  };

  const getAllUser = () => {
    setPage(1);
    setsDate("ALL");
    seteDate("ALL");
    $("#datePicker").removeClass("show");
    search == "" && dispatch({ type: OPEN_SPINNER_PROGRESS });
    dispatch(getFakeUser(page, rowsPerPage, search, sDate, eDate));
  };

  const collapsedDatePicker = () => {
    $("#datePicker").toggleClass("collapse");
  };

  const handleAddFakeUser = () => {
    localStorage.removeItem("fakeUser");
    history.push("/admin/fake/fakeUserDialog");
  };
  const handleEdit = (data) => {
    localStorage.setItem("fakeUser", JSON.stringify(data));

    history.push("/admin/fake/fakeUserDialog");
  };

  // const handleBLiveSwitch_ = (id) => {
  //   props.fakeUserIsLive(id);
  // };
  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", girl);
    });
  });

  return (
    <>
      <div id="content" class="main-content">
        <div
          class="layout-px-spacing mt-4"
          // style={{ background: "#060818" }}
        >
          <div className="row py-2">
            <div class="col-5">
              <h4>Fake User </h4>
            </div>
            <div class="col-7">
              <div class="breadcrumb-four float-right">
                <ul class="breadcrumb">
                  <li>
                    <Link to="/admin/dashboard">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-home"
                      >
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                      </svg>
                    </Link>
                  </li>
                  <li class="active">
                    <a href={() => false}> Fake User </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="my-2">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => handleAddFakeUser(data)}
            >
              <i className="fas fa-plus fa-lg mr-2 "></i>
              <span className="">New</span>
            </button>
          </div>
          <div class="row layout-top-spacing">
            <div id="tableDropdown" class="col-lg-12 col-12 layout-spacing">
              <div class="statbox widget  ">
                <div class="widget-content widget-content-area">
                  <div class="row ">
                    <div class="col-xl-8 col-md-8 col-sm-12 col-12">
                      <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                        <button
                          className="btn bg-purple-gradient text-white mr-2 "
                          onClick={getAllUser}
                        >
                          Life Time
                        </button>
                        <button
                          className="collapsed btn bg-purple-gradient text-white  "
                          value="check"
                          data-toggle="collapse"
                          data-target="#datePicker"
                          onClick={collapsedDatePicker}
                        >
                          Filter
                          <ExpandMoreIcon />
                        </button>
                        <p style={{ paddingLeft: 10 }} className="my-2 ">
                          {sDate !== "ALL" && sDate + " to " + eDate}
                        </p>
                      </div>
                    </div>
                    <div
                      id="datePicker"
                      className="collapse mt-5 pt-5 position-absolute"
                      aria-expanded="false"
                    >
                      <div className="container table-responsive">
                        <div key={JSON.stringify(date)}>
                          <DateRangePicker
                            onChange={(item) => {
                              setDate([item.selection]);
                              const dayStart = dayjs(
                                item.selection.startDate
                              ).format("YYYY-MM-DD");
                              const dayEnd = dayjs(
                                item.selection.endDate
                              ).format("YYYY-MM-DD");
                              setPage(1);
                              setsDate(dayStart);
                              seteDate(dayEnd);

                              props.getFakeUser(
                                page,
                                rowsPerPage,
                                search,
                                sDate,
                                eDate
                              );
                            }}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            ranges={date}
                            direction="horizontal"
                            editableDateInputs={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-4 float-right col-sm-12 col-12 filtered-list-search ">
                      <form class="form-inline my-2 my-lg-0 justify-content-center">
                        <div class="w-100">
                          <input
                            type="text"
                            class="w-100 form-control product-search br-30"
                            id="input-search"
                            placeholder="Search users..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <button
                            class="btn bg-primary-gradient  text-white"
                            type="button"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-search"
                            >
                              <circle cx="11" cy="11" r="8"></circle>
                              <line
                                x1="21"
                                y1="21"
                                x2="16.65"
                                y2="16.65"
                              ></line>
                            </svg>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table text-center  mb-4 table-striped">
                      <thead className="text-center">
                        <tr className="text-center">
                          <th>No</th>
                          <th>Image</th>
                          <th>CoverImage</th>
                          {/* <th>Video</th> */}
                          <th>Name</th>
                          <th>Username</th>
                          <th>Followers</th>
                          <th>Following</th>
                          <th>Created At</th>
                          <th>isBlock</th>
                          {/* <th>isLive</th> */}
                          <th>Edit</th>
                          <th>Details</th>
                          <th>History</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {data?.length > 0 ? (
                          <>
                            {data.map((value, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                      <img
                                        height="50px"
                                        width="50px"
                                        alt="app"
                                        src={value?.profileImage}
                                        style={{
                                          boxShadow:
                                            "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                          border: "2px solid #fff",
                                          borderRadius: 10,
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <img
                                        height="50px"
                                        width="50px"
                                        alt="app"
                                        src={value?.coverImage}
                                        style={{
                                          boxShadow:
                                            "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                          border: "2px solid #fff",
                                          borderRadius: 10,
                                        }}
                                      />
                                    </td>
                                    {/* <td>
                                      <video
                                        src={value?.video}
                                        height="50px"
                                        width="50px"
                                        style={{
                                          boxShadow:
                                            "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                          border: "2px solid #fff",
                                          borderRadius: 10,
                                          float: "left",
                                          objectFit: "cover",
                                        }}
                                        controls
                                      />
                                    </td> */}

                                    <td>{value?.name}</td>
                                    <td>{value?.username}</td>
                                    <td>{value?.followers}</td>
                                    <td>{value?.following}</td>

                                    <td>
                                      {dayjs(value.analyticDate).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </td>
                                    <td>
                                      <label class="switch s-icons s-outline  s-outline-primary mb-0">
                                        <input
                                          type="checkbox"
                                          checked={value?.isBlock}
                                          onClick={() =>
                                            handleBlockUnblockSwitch_(value._id)
                                          }
                                        />
                                        <span class="slider round"></span>
                                      </label>
                                    </td>
                                    {/* <td>
                                      <label class="switch s-icons s-outline  s-outline-primary mb-0">
                                        <input
                                          type="checkbox"
                                          checked={value?.isLive}
                                          onClick={() =>
                                            handleBLiveSwitch_(value._id)
                                          }
                                        />
                                        <span class="slider round"></span>
                                      </label>
                                    </td> */}
                                    <td class="text-center">
                                      <a
                                        class=" shadow-none info-button badge badge-lg badge-info p-2"
                                        to="/admin/userInfo"
                                        onClick={() => handleEdit(value)}
                                        href
                                      >
                                        <i class="fas fa-edit "></i> Edit
                                      </a>
                                    </td>
                                    <td class="text-center">
                                      <a
                                        class=" shadow-none info-button badge badge-lg badge-info p-2"
                                        to="/admin/userInfo"
                                        onClick={() => handleUserInfo(value)}
                                        href
                                      >
                                        <i class="fas fa-info "></i> Info
                                      </a>
                                    </td>
                                    <td>
                                      {" "}
                                      <a
                                        class="shadow-none history-button badge badge-lg  p-2"
                                        onClick={() => handleUserHistory(value)}
                                        href
                                      >
                                        <i class="fas fa-clock"></i>
                                        {/* <i
                                        class="fas fa-edit "
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                        {"  "} History
                                      </a>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td colSpan="14" align="center">
                              Nothing to show!!
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <Pagination
                      page={page}
                      rowsPerPage={rowsPerPage}
                      userTotal={totalUser}
                      handleRowsPerPage={handleRowsPerPage}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getFakeUser,
  handleBlockUnblockSwitch,
  // fakeUserIsLive,
})(FakeUserTable);
