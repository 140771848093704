import React, { useEffect, useState } from "react";

//image
import Male from "../../assets/img/male.png";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

// dayjs
import dayjs from "dayjs";

//config
import { baseURL } from "../../util/config";

//routing
import { Link } from "react-router-dom";

//MUI
import { makeStyles, TablePagination } from "@material-ui/core";

// type
import { OPEN_BANNER_DIALOG } from "../../Store/banner/types";

//sweet alert
import { alert, permissionError, warning } from "../../util/Alert";

//action
import { getReportedUser } from "../../Store/Report/report.action";

//table pagination
import TablePaginationActions from "./TablePagination";
import BannerDialog from "../Dialog/BannerDialog";
//jquery
import $ from "jquery";

import noImage from "../../assets/img/boy.png";

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  selectDropdown: { color: "#fff", backgroundColor: "#1b1f38" },
  menuItem: {
    "&:hover": {
      backgroundColor: "#3b3f58",
    },
  },
});

const UserReport = (props) => {
  const classes = useStyles2();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getReportedUser());
  }, []);
  const report = useSelector((state) => state.report.report);

  useEffect(() => {
    setData(report);
  }, [report]);

  //pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", noImage);
    });
  });

  return (
    <>
      <div id="content" class="main-content">
        <div class="layout-px-spacing mt-4">
          <div className="row py-2">
            <div class="col-3">
              <h4 className="live">User Report</h4>
            </div>
            <div class="col-9">
              <div class="breadcrumb-four float-right">
                <ul class="breadcrumb">
                  <li>
                    <Link to="/admin/dashboard">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-home"
                      >
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                      </svg>
                    </Link>
                  </li>
                  <li class="active">
                    <a href="javscript:void(0);"> User Report </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row layout-top-spacing">
            <div id="tableDropdown" class="col-lg-12 col-12 layout-spacing">
              <div class="statbox widget  ">
                <div class="widget-content widget-content-area">
                  <div class="table-responsive">
                    <table class="table text-center table-striped  mb-4">
                      <thead classNmae="text-center">
                        <tr>
                          <th>No</th>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Diamond</th>
                          <th>Coin</th>
                          <th>Count</th>
                          <th>Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 ? (
                          <>
                            {data
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((data, index) => {
                                return (
                                  <>
                                    <tr
                                      data-toggle="collapse"
                                      data-target={`#demo${index}`}
                                      class="accordion-toggle pointer-cursor"
                                    >
                                      <td>{index + 1}</td>
                                      <td>
                                        <img
                                          height="50px"
                                          width="50px"
                                          alt="app"
                                          src={
                                            data?._id?.profileImage
                                              ? data?._id?.profileImage
                                              : Male
                                          }
                                          className="mx-auto"
                                          style={{
                                            boxShadow:
                                              "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                            border: "2px solid #fff",
                                            borderRadius: 10,

                                            display: "block",
                                          }}
                                        />
                                      </td>
                                      <td>{data?._id?.name}</td>

                                      <td>{data?._id?.diamond}</td>
                                      <td>{data?._id?.coin}</td>
                                      <td>{data?.count}</td>
                                      <td>
                                        <a
                                          class=" shadow-none info-button badge badge-lg badge-info p-2"
                                          to="/admin/userInfo"
                                          // onClick={() => handleUserInfo(value)}
                                          href
                                        >
                                          <i class="fas fa-info "></i> Info
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="8" class="hiddenRow">
                                        <div
                                          id={`demo${index}`}
                                          class="accordian-body collapse"
                                        >
                                          <h6
                                            className="text-danger"
                                            style={{ textAlign: "start" }}
                                          >
                                            Report User Detail
                                          </h6>
                                          <table className="w-100">
                                            <thead>
                                              <tr>
                                                <th>No</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Diamond</th>
                                                <th>Coin</th>
                                                <th>Description</th>
                                                <th>Arrived on</th>
                                              </tr>
                                            </thead>
                                            <tbody
                                              style={{
                                                maxHeight: 100,
                                                overflowY: "auto",
                                              }}
                                            >
                                              {data?.report.length > 0 ? (
                                                data?.report.map(
                                                  (report, no) => {
                                                    return (
                                                      <tr key={no}>
                                                        <td>{no + 1}</td>
                                                        <td>
                                                          <img
                                                            height="35px"
                                                            width="35px"
                                                            alt="app"
                                                            src={
                                                              report?.toUserId
                                                                ?.profileImage
                                                                ? report
                                                                    ?.toUserId
                                                                    .profileImage
                                                                : Male
                                                            }
                                                            style={{
                                                              boxShadow:
                                                                "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                              border:
                                                                "2px solid #fff",
                                                              borderRadius: 10,
                                                              objectFit:
                                                                "cover",
                                                            }}
                                                          />
                                                        </td>
                                                        <td>
                                                          {
                                                            report?.toUserId
                                                              ?.name
                                                          }
                                                        </td>
                                                        <td class="text-success">
                                                          {
                                                            report?.toUserId
                                                              ?.diamond
                                                          }
                                                        </td>
                                                        <td class="text-warning">
                                                          {
                                                            report?.toUserId
                                                              ?.coin
                                                          }
                                                        </td>

                                                        <td>
                                                          {report?.description}
                                                        </td>

                                                        <td>
                                                          {dayjs(
                                                            report?.toUserId
                                                              ?.createdAt
                                                          ).format(
                                                            "DD MMM, YYYY"
                                                          )}
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <tr>
                                                  <td
                                                    colSpan="7"
                                                    align="center"
                                                  >
                                                    Nothing to show!!
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </>
                        ) : (
                          <tr>
                            <td colSpan="10" align="center">
                              Nothing to show!!
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div style={{ color: "white" }}>
                      <TablePagination
                        id="pagination"
                        component="div"
                        class=" pagination-custom_outline"
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          100,
                          { label: "All", value: data?.length },
                        ]}
                        count={data?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                          MenuProps: {
                            classes: { paper: classes.selectDropdown },
                          },
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BannerDialog />
    </>
  );
};

export default connect(null, { getReportedUser })(UserReport);
