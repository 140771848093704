import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { baseURL } from "../../util/config";
import { getFakeUser } from "../../Store/FakeUser/Action";
import { insertVideo, editVideo } from "../../Store/FakeReel/action";
import { permissionError } from "../../util/Alert";

const FakeVideoDialogue = (props) => {
  const { user } = useSelector((state) => state.fakeUser);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [show, setShow] = useState("");

  const [thumbnail, setThumbnail] = useState([]);
  const [thumbnailType, setThumbnailType] = useState(0);
  const [thumbnailPath, setThumbnailPath] = useState("");
  const [showThumbnail, setShowThumbnail] = useState("");

  const [screenshot, setScreenshot] = useState([]);
  const [screenshotType, setScreenshotType] = useState(0);
  const [screenshotPath, setScreenshotPath] = useState("");
  const [showScreenShot, setShowScreenShot] = useState("");

  const [video, setVideo] = useState([]);
  const [videoPath, setVideoPath] = useState("");
  const [videoType, setVideoType] = useState(0);
  const [showVideo, setShowVideo] = useState("");

  const [user_, setUser] = useState("");
  const [caption, setCaption] = useState("");
  const [location, setLocation] = useState("");
  const [mongoId, setMongoId] = useState("");
  const [userData, setUserData] = useState([]);

  const dataLocation = useLocation();

  const [errors, setError] = useState({
    video: "",
    screenshot: "",
    show: "",
    thumbnail: "",
    user_: "",
  });
  const hasPermission = useSelector((state) => state.admin.user.flag);

  useEffect(() => {
    dispatch(getFakeUser(1, 10, "", "ALL", "ALL", "Fake"));
  }, [dispatch]);

  const detail = dataLocation.state;
  useEffect(() => {
    if (detail) {
      setScreenshotType(detail?.screenshotType);
      setThumbnailType(detail?.thumbnailType);
      setUser(detail?.userId?._id);
      setShow(detail?.showVideo?.toString());
      setLocation(detail.location);
      setVideoType(detail?.videoType);
      setCaption(detail?.caption);
      setMongoId(detail?._id);

      if (detail?.videoType === 0) {
        setVideoPath(detail?.video);
      } else if (detail?.videoType === 1) {
        setShowVideo(detail?.video);
      }

      if (detail?.screenshotType === 0) {
        setScreenshotPath(detail?.screenshot);
      } else if (detail?.screenshotType === 1) {
        setShowScreenShot(detail?.screenshot);
      }

      if (detail?.thumbnailType === 0) {
        setThumbnailPath(detail?.thumbnail);
      } else if (detail?.thumbnailType === 1) {
        setShowThumbnail(detail?.thumbnail);
      }
    }
  }, [detail]);

  const HandleInputScreenshot = (e) => {
    if (e.target.files[0] == "") {
      errors.screenshot = "Image Is Required";
    } else {
      errors.screenshot = "";
    }
    setScreenshot(e.target.files[0]);
    setScreenshotPath(URL.createObjectURL(e.target.files[0]));
    setShowScreenShot(URL.createObjectURL(e.target.files[0]));
  };

  const HandleInputThumbnail = (e) => {
    if (e.target.files[0] == "") {
      errors.thumbnail = "Image Is Required";
    } else {
      errors.thumbnail = "";
    }
    setThumbnail(e.target.files[0]);
    setThumbnailPath(URL.createObjectURL(e.target.files[0]));
    setShowThumbnail(URL.createObjectURL(e.target.files[0]));
  };
  // const HandleInputVideo = (e) => {
  //   if (e.target.files[0] == "") {
  //     errors.video = "Video Is Required";
  //   } else {
  //     errors.video = "";
  //   }
  //   setVideo(e.target.files[0]);
  //   setVideoPath(URL.createObjectURL(e.target.files[0]));
  // };

  //   const selectedFile = event.target.files[0];
  //   setVideoFile(selectedFile);

  //   const videoElement = document.createElement("video");
  //   videoElement.src = URL.createObjectURL(selectedFile);

  //   videoElement.addEventListener("loadedmetadata", () => {
  //     const durationInSeconds = videoElement.duration;
  //     const durationInMilliseconds = durationInSeconds * 1000;
  //     setVideoTime(parseInt(durationInMilliseconds));
  //   });
  // };

  const [videoTime, setVideoTime] = useState(0);

  const HandleInputVideo = (event) => {
    if (event.target.files[0] == "") {
      errors.video = "Video Is Required";
    } else {
      errors.video = "";
    }
    setVideo(event.target.files[0]);
    const selectedFile = event.target.files[0];
    const videoElement = document.createElement("video");
    setVideoPath(URL.createObjectURL(event.target.files[0]));
    setShowVideo(URL.createObjectURL(event.target.files[0]));
    videoElement.src = URL.createObjectURL(selectedFile);

    videoElement.addEventListener("loadedmetadata", () => {
      const durationInSeconds = videoElement.duration;
      const durationInMilliseconds = durationInSeconds * 1000;
      setVideoTime(parseInt(durationInMilliseconds));
    });
  };

  const history = useHistory();

  const isLink = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^(ftp|http|https):\/\/[^ "]+$/.test(val);
    return validNumber;
  };

  const handleSubmit = () => {
    if (
      !location ||
      !user_ ||
      !user === "Select user" ||
      !show ||
      !caption ||
      (videoType === 0 && (!videoPath || !isLink(videoPath))) ||
      (screenshotType === 0 && (!screenshotPath || !isLink(screenshotPath))) ||
      (thumbnailType === 0 && (!thumbnailPath || !isLink(thumbnailPath))) ||
      (videoType === 1 && !video) || // Add && here
      (thumbnailType === 1 && !thumbnail) || // Add && here
      (screenshotType === 1 && !screenshot)
    ) {
      debugger;
      let error = {};
      if (!location) error.location = "Location is Required";
      if (!caption) error.caption = "caption is Required";
      if (!user_ || user_ === "Select user") error.user_ = "User is Required";
      if (!show) error.show = "Video Type is Required";

      if (videoType === 0 && (!videoPath || !isLink(videoPath))) {
        error.video = "Invalid Video Link!";
      }

      if (thumbnailType === 0 && (!thumbnailPath || !isLink(thumbnailPath))) {
        error.thumbnail = "Invalid Thumbnail Link!";
      }

      if (
        screenshotType === 0 &&
        (!screenshotPath || !isLink(screenshotPath))
      ) {
        error.screenshot = "Invalid Screenshot Link!";
      }

      if (videoType === 1 && !video) {
        error.video = "Video is required !";
      }
      if (screenshotType === 1 && !screenshot) {
        error.screenshot = "Screenshot is required !";
      }
      if (thumbnailType === 1 && !thumbnail) {
        error.thumbnail = "Thumbnail is required !";
      }

      return setError({ ...error });
    } else {
      if (!hasPermission) return permissionError();
      const formData = new FormData();
      debugger;
      formData.append("thumbnailType", thumbnailType);
      if (thumbnailType === 0) {
        formData.append("thumbnail", thumbnailPath);
      } else {
        formData.append("thumbnail", thumbnail);
      }
      formData.append("showVideo", parseInt(show));
      formData.append("userId", user_);
      formData.append("screenshotType", screenshotType);
      if (screenshotType === 0) {
        formData.append("screenshot", screenshotPath);
      } else {
        formData.append("screenshot", screenshot);
      }
      formData.append("videoType", videoType);
      if (videoType === 0) {
        formData.append("video", videoPath);
      } else {
        formData.append("video", video);
      }
      formData.append("view", 0);

      formData.append("location", location);
      formData.append("caption", caption);

      formData.append("allowComment", true);
      formData.append("duration", videoTime);
      if (mongoId) {
        props.editVideo(mongoId, formData);
      } else {
        props.insertVideo(formData);
      }
      // history.push("/admin/reel/fake");
    }
  };

  return (
    <div id="content" class="main-content">
      <div class="layout-px-spacing mt-4">
        <div className="row py-2">
          <div class="col-xl-6 col-md-6 col-sm-12 col-12">
            <h4>Fake Reel </h4>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12 col-12 ">
            <div class="breadcrumb-four float-right">
              <ul class="breadcrumb">
                <li>
                  <Link to="/admin/dashboard">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-home"
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                  </Link>
                </li>
                <li class="active">
                  <a href="javscript:void(0);"> Fake Reel </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body card-overflow">
                <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-gray mb-2">user</label>

                        <>
                          <select
                            class="form-select form-control"
                            aria-label="Default select example"
                            value={user_}
                            onChange={(e) => {
                              setUser(e.target.value);
                              if (e.target.value === "Selectuser") {
                                return setError({
                                  ...errors,
                                  user_: "Please select a user!",
                                });
                              } else {
                                return setError({
                                  ...errors,
                                  user_: "",
                                });
                              }
                            }}
                          >
                            <option value="Selectuser">Select user</option>
                            {user?.map((user) => {
                              return user.name == detail?.userId?.name ? (
                                <option value={user?._id} selected>
                                  {user?.name}
                                </option>
                              ) : (
                                <option value={user?._id}>{user?.name}</option>
                              );
                            })}
                          </select>
                          {errors.user_ && (
                            <div className="ml-2 mt-1">
                              {errors.user_ && (
                                <div className="pl-1 text__left">
                                  <span style={{ color: "red" }}>
                                    {errors.user_}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      </div>
                    </div>

                    <div className="col-md-6  justify-content-start mt-5">
                      <div className="d-flex">
                        <label className="mb-2 text-gray">Show Video : </label>
                        <div class="form-check">
                          <input
                            class="form-check-input "
                            type="radio"
                            name="show"
                            id="public"
                            value="0"
                            checked={show === "0" ? true : false}
                            onChange={(e) => {
                              setShow(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...errors,
                                  show: " Video is required!",
                                });
                              } else {
                                return setError({
                                  ...errors,
                                  show: "",
                                });
                              }
                            }}
                          />
                          <label class="form-check-label" for="public">
                            Public
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input "
                            type="radio"
                            name="show"
                            id="private"
                            value="1"
                            checked={show === "1" ? true : false}
                            onChange={(e) => {
                              setShow(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...errors,
                                  show: " Video is required!",
                                });
                              } else {
                                return setError({
                                  ...errors,
                                  show: "",
                                });
                              }
                            }}
                          />
                          <label class="form-check-label" for="private">
                            Private
                          </label>
                        </div>
                      </div>
                      <div>
                        {errors.show && (
                          <div className="text-start mt-1 d-block">
                            {errors.show && (
                              <div className="pl-1 text__left">
                                <span style={{ color: "red" }}>
                                  {errors.show}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label className="mb-2 text-gray">location</label>
                        <input
                          type="text"
                          className="form-control"
                          required=""
                          placeholder="location"
                          value={location}
                          onChange={(e) => {
                            setLocation(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...errors,
                                location: " Location is required!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                location: "",
                              });
                            }
                          }}
                        />
                        {errors.location && (
                          <div className="text-start mt-1 d-block">
                            {errors.location && (
                              <div className="pl-1 text__left">
                                <span style={{ color: "red" }}>
                                  {errors.location}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label className="mb-2 text-gray">Caption</label>
                        <textarea
                          rows={3}
                          cols={30}
                          className="form-control"
                          required=""
                          placeholder="caption"
                          value={caption}
                          onChange={(e) => {
                            setCaption(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...errors,
                                caption: "Caption is reqiured!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                caption: "",
                              });
                            }
                          }}
                        />
                        {errors.caption && (
                          <div className="text-start mt-1 d-block">
                            {errors.caption && (
                              <div className="pl-1 text__left">
                                <span style={{ color: "red" }}>
                                  {errors.caption}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="row mt-4">
                        <div className="col-md-12 d-flex justify-content-start">
                          <label className="mb-2 text-gray">
                            Thumbnail Type :
                          </label>
                          <div class="form-check">
                            <input
                              class="form-check-input "
                              type="radio"
                              name="thumbnailType"
                              id="thumbnail"
                              value="1"
                              onClick={(e) => {
                                setThumbnailType(e.target.value);
                              }}
                              checked={thumbnailType == 1 ? true : false}
                            />
                            <label class="form-check-label" for="image">
                              Image
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="thumbnailType"
                              id="linkThumbnail"
                              value="0"
                              checked={thumbnailType == 0 ? true : false}
                              onClick={(e) => {
                                setThumbnailType(e.target.value);
                              }}
                            />
                            <label class="form-check-label" for="linkThumbnail">
                              Link
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div
                          className={
                            thumbnailType == 1 ? "col-md-12" : "d-none"
                          }
                        >
                          <div className="form-group ">
                            <label className="mb-2 text-gray">Image</label>
                            <input
                              type="file"
                              className="form-control form-control-sm"
                              accept="image/*"
                              required=""
                              onChange={HandleInputThumbnail}
                            />
                            {errors.thumbnail && (
                              <div className="ml-2 mt-1">
                                {errors.thumbnail && (
                                  <div className="pl-1 text__left">
                                    <span style={{ color: "red" }}>
                                      {errors.thumbnail}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                            {showThumbnail && (
                              <>
                                <img
                                  height="60px"
                                  width="60px"
                                  alt="app"
                                  src={showThumbnail}
                                  style={{
                                    boxShadow:
                                      "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                    border: "2px solid #fff",
                                    borderRadius: 10,
                                    marginTop: 10,
                                    float: "left",
                                    objectFit: "contain",
                                    marginRight: 15,
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          className={
                            thumbnailType == 0 ? "col-md-12" : "d-none"
                          }
                        >
                          <div className="form-group">
                            <label className="mb-2 text-gray">
                              Thumbnail Link
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required=""
                              placeholder="ThumbnailType link "
                              value={thumbnailPath}
                              onChange={(e) => {
                                setThumbnailPath(e.target.value);
                                if (!e.target.value) {
                                  return setError({
                                    ...errors,
                                    thumbnail: "Thumbnail is Required!",
                                  });
                                } else {
                                  return setError({
                                    ...errors,
                                    thumbnail: "",
                                  });
                                }
                              }}
                            />
                            {errors.thumbnail && (
                              <div className="ml-2 mt-1">
                                {errors.thumbnail && (
                                  <div className="pl-1 text__left">
                                    <span className="text-danger">
                                      {errors.thumbnail}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          {thumbnailPath && (
                            <>
                              <img
                                height="60px"
                                width="60px"
                                alt="app"
                                src={thumbnailPath}
                                style={{
                                  boxShadow:
                                    "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                  border: "2px solid #fff",
                                  borderRadius: 10,
                                  marginTop: 10,
                                  float: "left",
                                  objectFit: "contain",
                                  marginRight: 15,
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row mt-4">
                        <div className="col-md-12 d-flex justify-content-start">
                          <label className="mb-2 text-gray">
                            Screenshot Type :
                          </label>
                          <div class="form-check">
                            <input
                              class="form-check-input "
                              type="radio"
                              name="screenshotType"
                              id="screenshot"
                              value="1"
                              onClick={(e) => {
                                setScreenshotType(e.target.value);
                              }}
                              checked={screenshotType == 1 ? true : false}
                            />
                            <label class="form-check-label" for="image">
                              Image
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="screenshotType"
                              id="linkScreenshot"
                              value="0"
                              checked={screenshotType == 0 ? true : false}
                              onClick={(e) => {
                                setScreenshotType(e.target.value);
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="linkScreenshot"
                            >
                              Link
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div
                          className={
                            screenshotType == 1 ? "col-md-12" : "d-none"
                          }
                        >
                          <div className="form-group ">
                            <label className="mb-2 text-gray">Image</label>
                            <input
                              type="file"
                              className="form-control form-control-sm"
                              accept="image/*"
                              required=""
                              onChange={HandleInputScreenshot}
                            />
                            {errors.screenshot && (
                              <div className="ml-2 mt-1">
                                {errors.screenshot && (
                                  <div className="pl-1 text__left">
                                    <span className="text-danger">
                                      {errors.screenshot}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                            {showScreenShot && (
                              <>
                                <img
                                  height="60px"
                                  width="60px"
                                  alt="app"
                                  src={showScreenShot}
                                  style={{
                                    boxShadow:
                                      "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                    border: "2px solid #fff",
                                    borderRadius: 10,
                                    marginTop: 10,
                                    float: "left",
                                    objectFit: "contain",
                                    marginRight: 15,
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          className={
                            screenshotType == 0 ? "col-md-12" : "d-none"
                          }
                        >
                          <div className="form-group">
                            <label className="mb-2 text-gray">
                              {" "}
                              Screenshot Link
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required=""
                              placeholder="Screenshot link "
                              value={screenshotPath}
                              onChange={(e) => {
                                setScreenshotPath(e.target.value);
                                if (!e.target.value) {
                                  return setError({
                                    ...errors,
                                    screenshot: "Screenshot is Required!",
                                  });
                                } else {
                                  return setError({
                                    ...errors,
                                    screenshot: "",
                                  });
                                }
                              }}
                            />

                            {errors.screenshot && (
                              <div className="ml-2 mt-1">
                                {errors.screenshot && (
                                  <div className="pl-1 text__left">
                                    <span className="text-danger">
                                      {errors.screenshot}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          {screenshotPath && (
                            <>
                              <img
                                height="60px"
                                width="60px"
                                alt="app"
                                src={screenshotPath}
                                style={{
                                  boxShadow:
                                    "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                  border: "2px solid #fff",
                                  borderRadius: 10,
                                  marginTop: 10,
                                  float: "left",
                                  objectFit: "contain",
                                  marginRight: 15,
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-12 d-flex justify-content-start">
                      <label className="mb-2 text-gray">Video Type : </label>
                      <div class="form-check">
                        <input
                          class="form-check-input "
                          type="radio"
                          name="videoType"
                          id="video"
                          value="1"
                          onClick={(e) => {
                            setVideoType(e.target.value);
                          }}
                          checked={videoType == 1 ? true : false}
                        />
                        <label class="form-check-label" for="video">
                          Video
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="videoType"
                          id="linkVideo"
                          value="0"
                          checked={videoType == 0 ? true : false}
                          onClick={(e) => {
                            setVideoType(e.target.value);
                          }}
                        />
                        <label class="form-check-label" for="linkVideo">
                          Link
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className={videoType == 1 ? "col-md-6" : "d-none"}>
                      <div className="form-group ">
                        <label className="mb-2 text-gray">video</label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          accept="video/*"
                          required=""
                          controls
                          // value={}
                          onChange={HandleInputVideo}
                        />
                        {errors.video && (
                          <div className="ml-2 mt-1">
                            {errors.video && (
                              <div className="pl-1 text__left">
                                <span className="text-danger">
                                  {errors.video}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                        {showVideo && (
                          <>
                            <video
                              height="60px"
                              width="60px"
                              alt="app"
                              src={showVideo}
                              style={{
                                boxShadow:
                                  "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                marginTop: 10,
                                float: "left",
                                objectFit: "contain",
                                marginRight: 15,
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className={videoType == 0 ? "col-md-6" : "d-none"}>
                      <div className="form-group">
                        <label className="mb-2 text-gray">Video Link</label>
                        <input
                          type="text"
                          className="form-control"
                          required=""
                          placeholder="Video link "
                          value={videoPath}
                          onChange={(e) => {
                            setVideoPath(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...errors,
                                video: "Video is Required!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                video: "",
                              });
                            }
                          }}
                        />
                        {errors.video && (
                          <div className="ml-2 mt-1">
                            {errors.video && (
                              <div className="pl-1 text__left">
                                <span className="text-danger">
                                  {errors.video}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      {videoPath && (
                        <>
                          <video
                            height="60px"
                            width="60px"
                            alt="app"
                            src={videoPath}
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              marginTop: 10,
                              float: "left",
                              objectFit: "contain",
                              marginRight: 15,
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row justify-content-end mr-1">
                    <div className={videoType ? "mt-5 pt-5" : "mt-5"}>
                      <button
                        type="button"
                        className="btn btn-danger ml-2 btn-round float-right "
                        onClick={() => {
                          history.push("/admin/reel/fake");
                        }}
                      >
                        Close
                      </button>
                      {!mongoId ? (
                        <button
                          type="button"
                          className="btn btn-round float-right btn-info"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-round float-right btn-info"
                          onClick={handleSubmit}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { getFakeUser, insertVideo, editVideo })(
  FakeVideoDialogue
);
